import React from "react"
import EventDetails from "../../components/eventdetails"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { eventData } from "../../_mockdata/eventsData"
const event = eventData[1]
const FoundHerPage = () => (
  <Layout>
    <SEO
      title="FoundHER Career Fair with Plug and Play"
      description=""
      lang="en"
      meta={[]}
    />
    <EventDetails
      image={event.image}
      type={event.type}
      title={event.title}
      publishedDate={event.publishedDate}
      internalLink={event.internalLink!}
      eventDetails={event.eventDetails}
      id={event.id}
      eventDate={event.eventDate}
      location={event.location}
    />
  </Layout>
)

export default FoundHerPage
